// NEW CURRENCY UPDATE HERE
export type UserCurrencyOld = 'usd' | 'ars' | 'aud' | 'brl' | 'cad' | 'chf' | 'cny' | 'eur' | 'gbp' | 'inr' | 'jpy' | 'mxn' | 'ngn' | 'pln' | 'rub' | 'sek' | 'thb' | 'try' | 'vnd' | 'zar'
export type UserCurrency = 'usd' | 'aed' | 'aud' | 'brl' | 'cad' | 'chf' | 'cny' | 'eur' | 'gbp' | 'hkd' | 'inr' | 'jpy' | 'krw' | 'mxn' | 'nzd' | 'rub' | 'sek' | 'sgd' | 'try' | 'zar'


export interface UserCurrencyMap {
    [key: string]: string
}

// NEW CURRENCY UPDATE HERE
export const CURRENCY_LABEL_MAP: UserCurrencyMap = {
	// default
	usd: 'USD',

	// conversions
	aed: 'AED',
	aud: 'AUD',
	brl: 'BRL',
	cad: 'CAD',
	chf: 'CHF',
	cny: 'CNY',
	eur: 'EUR',
	gbp: 'GBP',
	hkd: 'HKD',
	inr: 'INR',
	jpy: 'JPY',
	krw: 'KRW',
	mxn: 'MXN',
	nzd: 'NZD',
	rub: 'RUB',
	sek: 'SEK',
	sgd: 'SGD',
	try: 'TRY',
	zar: 'ZAR',
}

// NEW CURRENCY UPDATE HERE
export const CURRENCY_SYMBOL_MAP: UserCurrencyMap = {
	// default
	usd: '$',

	// conversions
	aed: 'Dh',
	aud: '$',
	brl: 'R$',
	cad: '$',
	chf: '₣',
	cny: '¥',
	eur: '€',
	gbp: '£',
	hkd: '元',
	inr: '₹',
	jpy: '¥',
	krw: '₩',
	mxn: '$',
	nzd: '$',
	pln: 'zł',
	rub: '₽',
	sek: 'kr',
	sgd: 'S$',
	try: '₺',
	zar: 'R',
}
